
































import Authentication from "@/lib/Authentication";
import Vue from "vue";

export default Vue.extend({
  name: "OAuthLoginController",

  data() {
    return {
      handlingLogin: false,
      loginError: null as string | null,
    };
  },

  created() {
    if (this.$store.getters["auth/isLoggedIn"]) {
      this.redirect();
    }
  },

  methods: {
    redirect() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, "redirect")) {
        this.$router.push(this.$route.query.redirect as string);
      } else {
        this.$router.push({ name: "app" });
      }
    },

    /**
     * Returns TRUE if the current user is logged in; otherwise FALSE.
     *
     * @return {boolean}
     */
    isLoggedIn() {
      return false;
    },

    /**
     * Start the login process.
     */
    handleLogin() {
      this.loginError = null;

      Authentication.processOauthLoginReturnTokenOnly()
        .then((keys) => {
          if (typeof keys !== "string" && keys !== null) {
            Authentication.setAuthTokens(keys).then(() => {
              this.redirect();
            });
          }
        })
        .catch((error) => {
          this.loginError = "There was a problem trying to login.";
        })
        .finally(() => {
          this.handlingLogin = false;
        });
    },
  },
});
